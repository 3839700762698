import SellHeader from '../shared/sellHeader'
// import PropTypes from "prop-types";
import { ModalContext } from 'Context/ModalContext'
import { useContext, useEffect, useState } from 'react'
import { Sharedata } from './modalContents/Sharecontent'
import ReviewContent from './modalContents/ReviewContents'
import { ReportItem, Reportseller } from './modalContents/Reports'
import Checkout from './Checkout'
import OrderFulfilment from './modalContents/managestore/Orderfulfil'
import MarkSold from './modalContents/managestore/marksold'
import Sellanitem from './Sellanitem/sellitem'
import Createbusiness from './createbusiness/createbusiness'
import { useLocation } from 'react-router-dom'
import toast from 'react-hot-toast' // Make sure to import toast

const Review = () => {
  const { currentCheck, movetoReview } = useContext(ModalContext)

  const reverseAction = () => {
    currentCheck !== 'readReview' && movetoReview()
  }
  return (
    <>
      <div>
        <SellHeader
          clickBack={reverseAction}
          title={
            currentCheck === 'readReview'
              ? 'Reviews'
              : currentCheck === 'addReview'
              ? 'Rate this Product'
              : 'Nothing'
          }
          // hiding-back-arrows
          hideArr={currentCheck === 'readReview' ? true : false}
          // hiding-close-icon
          hide={false}
        />
        <div>
          <ReviewContent />
        </div>
      </div>
    </>
  )
}

export const CommerceModal = () => {
  const { isOpen, contentType } = useContext(ModalContext)
  const [classmobile, setClassmobile] = useState('')
  const location = useLocation()
  // get-screen-size-andupdate-state
  // targeting the current screen and current modal and setting dynamic classes for it

  const baseUrl = `https://www.fisolakonlinestore.com.ng`

  const handleCopyUrl = async () => {
    const urlText = baseUrl + location?.pathname
    try {
      await navigator.clipboard.writeText(urlText)
      toast.success('Link copied successfully')
    } catch (err) {
      console.error('Failed to copy: ', err)
      toast.error('Failed to copy link')
    }
  }

  useEffect(() => {
    if (window.innerWidth <= 725) {
      console.log('targeted')
      switch (contentType) {
        case 'sharemodal':
          setClassmobile('share_mobile')
          break
        case 'marksold':
          setClassmobile('share_mobile')
          break
        default:
          setClassmobile('mobile_view_modal')
      }
    }
  }, [contentType])
  return (
    <div
      className={` ${isOpen ? 'block' : 'hidden'}  bg-[#ffff] ${
        (contentType === 'sharemodal' || contentType === 'marksold') &&
        'p-10 rounded-xl'
      } z-[994] mx-auto fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] overflow-y-auto max-h-[95vh] overlay_modal ${classmobile} animate_pop_fade`}
    >
      {contentType === 'sharemodal' ? (
        <Sharedata handleCopyUrl={handleCopyUrl} />
      ) : contentType === 'reviewitem' ? (
        <Review />
      ) : contentType === 'reportitem' ? (
        <ReportItem />
      ) : contentType === 'reportseller' ? (
        <Reportseller />
      ) : contentType === 'checkout' ? (
        <Checkout />
      ) : contentType === 'orderfulfilment' ? (
        <OrderFulfilment />
      ) : contentType === 'marksold' ? (
        <MarkSold />
      ) : contentType === 'sellitem' ? (
        <Sellanitem />
      ) : contentType === 'createbusiness' ? (
        <Createbusiness />
      ) : null}
    </div>
  )
}
// Review.propTypes = {
//   content: PropTypes.node.isRequired,
// };
export default Review
