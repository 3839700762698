import { useEffect, useState } from 'react'
import './style.css'
import { AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai'
import SignupImage from './SignupImage.jpg'

const WelcomeComp = () => {
  const [currentIndex, setCurrentIndex] = useState(0)

  return (
    <div className='welcome-comp-box'>
      <img src={'fisolak.jpg'} alt='' />
      <div className=' up-wel  !text-purple-950'>Welcome to Fisolak</div>

      <div className='ad-main-con'>
        <div className='ad-main'>
          <div className='welc-navi-box'>
            <div className='ad-more-tst bvb !text-purple-950'>
              Welcome to Fisolak
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WelcomeComp
