import { Fab } from '@mui/material'
import Stacked from './Stacked'
import Navcategory from './navCategory'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'

export const Category = ({ src, title }) => {
  const fabStyles = {
    background: '#fff',
    width: '40px',
    height: '40px',
    zIndex: '1',
  }
  return (
    <>
      <Stacked d='column' g='4px' ai='center'>
        <Fab sx={fabStyles}>
          <img src={src} alt='icon' className='' width={25} height={25} />
        </Fab>
        <p
          style={{
            color: '#ffff',
            textAlign: 'center',
          }}
          className='text-[10px] md:text-[12px]'
        >
          {title}
        </p>
      </Stacked>
    </>
  )
}

const useMobileView = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isMobile
}

const Categorynav = () => {
  const isMobile = useMobileView()

  const categoriesToShow = isMobile ? Navcategory.slice(0, -1) : Navcategory
  return (
    <>
      {Navcategory.map((category) => {
        return (
          <Link
            to={`/commerce/${category.title.toLocaleLowerCase()}`}
            key={category.id}
            style={{ textDecoration: 'none' }}
          >
            <Category src={category.icon} title={category.title} />
          </Link>
        )
      })}
    </>
  )
}
export default Categorynav
