import recipe from "assets/images/recipe.png";
const order_History = [
  // {
  //   id: 1,
  //   item_bg: recipe,
  //   order_date: "9 jun 2023",
  //   item_name: "Serendipitous Whispers of Enchanted Moonlight Elixi",
  //   order_total_price: "N16,098",
  //   order_status: "Delivery in progress",
  //   order_number: "#12348901",
  //   status_code: 1,
  //   delivery_fee: "N 2,950",
  //   total_amount: "N 12,960",
  //   pay_mth: "Wallet",
  //   item_total: "N 10,000",
  //   seller_name: "Faith Awokunle",
  //   seller_address:
  //     "Street 123, Town 456. City 567, Country 789, +2348166609120A",
  //   verified: true,
  // },
  // {
  //   id: 2,
  //   item_bg: recipe,
  //   order_date: "9 jun 2023",
  //   item_name: "Serendipitous Whispers of Enchanted Moonlight Elixi",
  //   order_total_price: "N16,098",
  //   order_status: "Delivered",
  //   order_number: "#12348901",
  //   status_code: 2,
  //   delivery_fee: "N 2,950",
  //   total_amount: "N 12,960",
  //   pay_mth: "Wallet",
  //   item_total: "N 10,000",
  //   seller_name: "Faith Awokunle",
  //   seller_address:
  //     "Street 123, Town 456. City 567, Country 789, +2348166609120A",
  //   verified: true,
  // },
  // {
  //   id: 3,
  //   item_bg: recipe,
  //   order_date: "9 jun 2023",
  //   item_name: "Serendipitous Whispers of Enchanted Moonlight Elixi",
  //   order_total_price: "N16,098",
  //   order_status: "Cancelled",
  //   order_number: "#12348901",
  //   status_code: 3,
  //   delivery_fee: "N 2,950",
  //   total_amount: "N 12,960",
  //   pay_mth: "Wallet",
  //   item_total: "N 10,000",
  //   seller_name: "Faith Awokunle",
  //   seller_address:
  //     "Street 123, Town 456. City 567, Country 789, +2348166609120A",
  //   verified: true,
  // },
];

export { order_History };
