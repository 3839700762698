import { useEffect, useState, useContext } from 'react'
import Categorynav from '../../shared/category'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Box, Button } from '@mui/material'
import { ModalContext } from 'Context/ModalContext'
import { ButtonSide } from 'components/newCommerce/shared/sideButton'

const MobileNav = () => {
  const {
    topProduct,
    isOpen,
    setModalContent,
    openModal,
    storeContext,
    isAdmin,
  } = useContext(ModalContext)
  const [comp, setComp] = useState(Link)
  const [disable, setDisable] = useState(false)
  const mangeStore = isAdmin ? 'Manage Store' : ''

  const location = useLocation()

  const [content, setContent] = useState(mangeStore)

  const [Cname, setCname] = useState(true)

  useEffect(() => {
    switch (!location.pathname) {
      case '/commerce':
        setCname(false)
        break
      case '/commerce/':
        setCname(false)
        break
      default:
        setCname(true)
    }
  }, [location, topProduct])

  useEffect(() => {
    if (isAdmin) {
      if (
        location.pathname === '/commerce/managestore/' ||
        location.pathname === '/commerce/managestore'
      ) {
        setContent('Sell an Item')
        setComp(Button)
        setDisable(storeContext.length < 1)
      }
    }
  }, [isAdmin, location, storeContext])

  const SellItem = (payload) => {
    if (content === 'Sell an Item' && comp === Button) {
      setModalContent(payload)
      !isOpen && openModal()
    } else {
      return
    }
  }
  return (
    <div
      className={`${
        !Cname ? 'dissapear_mobile' : ''
      }  sticky top-[0px] z-[30] mobile_category_container`}
    >
      <div className='bg-[rgb(79,13,163)] flex flex-wrap gap-x-[1.6rem] pt-[1rem] p-b-[1rem] px-[1.4rem] overflow-x-auto mobile_category'>
        <Categorynav />
      </div>

      {isAdmin && (
        <ButtonSide
          cp={comp}
          title={content}
          bg='#FF8A15'
          w='100%'
          route='/commerce/managestore'
          click={() => SellItem('sellitem')}
          isDisabled={disable}
        />
      )}
    </div>
  )
}

export default MobileNav
